import { all, put, takeLatest } from "redux-saga/effects";

// Api
import {
  createCampaignMedia,
  getCampaignMediaByCity,
} from "../../../apis/CampaignMediaAPI";

// Constants
import { ExploreSites } from "../../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";
import { MediaSites } from "../../../constants/action-constants/map-view/media-sites/MediaSitesActionConstants";
import { defaultPagination } from "../../../constants/UrlConstants";

export function* addExploreSiteToCampaign(action) {
  const { campaignId, campaignMediaInfo } = action.payload;

  const { price, mediaId, pricing, cityId } = campaignMediaInfo;
  try {
    const campaignMediaBean = {
      price,
      priceFactor: 1,
      mediaId,
      priceMode: "FIXED",
      sellerPrice: pricing.price,
    };

    // api call to add inventory media to campaign
    yield createCampaignMedia(campaignId, "", campaignMediaBean);

    // api call to refresh campaign media list after media is added
    const data = yield getCampaignMediaByCity(
      campaignId,
      cityId,
      defaultPagination.pageNo,
      defaultPagination.pageSize
    );

    yield put({
      type: ExploreSites.ADD_EXPLORE_SITE_SUCCESS,
      payload: { mediaId },
    });

    yield put({
      type: MediaSites.GET_MEDIA_SITES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ExploreSites.ADD_EXPLORE_SITE_FAILURE,
      payload: { err, mediaId },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ExploreSites.ADD_EXPLORE_SITE, addExploreSiteToCampaign),
  ]);
}
