import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  cancelSiteMonitoring,
  openSetupMonitoringForm,
  selectMediaSite,
  unselectMediaSite,
} from "../../../actions/campaign-monitoring/MonitoringSettingsActions";

// Constants and Utils
import {
  constructDateString,
  constructSingleDateString,
} from "../../../common-utils/date-utils/DateUtils";
import {
  DATE_FORMATS,
  FormDataTargets,
} from "../../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import Hamburger from "../../../components/hamburger-menu/Hamburger";
import {
  DefaultImage,
  InfoCell,
  SelectionCell,
} from "../../../components/campaign-media-table-row/MediaRow";

// Page-Constants
const frequencyDaysObject = {
  1: "Daily",
  2: "Once In 2 Days",
  3: "Once In 3 Days",
  7: "Weekly Once",
};

// Page Components
function ContactCell({ monitorUser }) {
  const { name, emailId, phoneNo } = monitorUser;
  return (
    <td className="p-2">
      <span className="d-block">{name}</span>
      <span className="d-block">{emailId}</span>
      <span className="d-block">{phoneNo}</span>
    </td>
  );
}

function MonitoringSettingCell({ settings = {} }) {
  const {
    frequencyDays: frequencyDaysInCount,
    startTimestamp,
    endTimestamp,
    isActive,
    cancelledOn,
  } = settings;
  const frequencyDaysInString = frequencyDaysObject[frequencyDaysInCount];

  // Constructing the Date String for monitoring
  const monitoringDateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  // cancel date string
  const cancelledDateString = constructSingleDateString(
    cancelledOn,
    DATE_FORMATS.full_month_with_date_year
  );

  return (
    <td className="p-2">
      <span className="d-block">{frequencyDaysInString}</span>
      <span className="d-block">{monitoringDateString}</span>
      <span className="d-block text-muted">
        {!isActive ? `Cancelled On: ${cancelledDateString}` : ""}
      </span>
    </td>
  );
}

function MonitorMediaImage({ latestLog }) {
  const imageUrl = latestLog?.images[0]?.originalUrl;

  if (!imageUrl) {
    return <DefaultImage />;
  }
  return (
    <img className="media__site-image" src={imageUrl} alt="thumbnail-image" />
  );
}

function LatestUpdateCell({ mediaElementStyle, latestLog, media }) {
  const { id } = useParams();
  const mediaId = media.id;

  // Constructing the Date String
  const latestUpdateDate = constructSingleDateString(
    latestLog.updatedOn,
    DATE_FORMATS.full_month_with_date_year
  );

  // Url
  const mediaLogPageUrl = RedirectTo.monitorMediaLogPageUrl
    .replace(":campaignId", id)
    .replace(":mediaId", mediaId);

  return (
    <td className={mediaElementStyle}>
      <div className="d-flex">
        <MonitorMediaImage latestLog={latestLog} />
        <div className="pl-2">
          <span className="d-block">{latestUpdateDate}</span>
          <Link to={mediaLogPageUrl} target="_blank">
            {"View all updates"}
          </Link>
        </div>
      </div>
    </td>
  );
}

function MediaSettingsDetailsCells({ item }) {
  const dispatch = useDispatch();
  const { id: campaignId, cityId } = useParams();
  const { settings = {}, monitorUser, latestLog, media = {} } = item;
  const { id: mediaId } = media;
  const { id: settingId, isActive } = settings; // isActive:false, (after "CancelSiteMonitoring")

  // Open Modal Function
  function openSetupMonitoringFormFunction() {
    dispatch(openSetupMonitoringForm(mediaId, {}, settingId));
  }

  return (
    <>
      <ContactCell monitorUser={monitorUser} />
      <MonitoringSettingCell settings={settings} />
      {latestLog ? (
        <LatestUpdateCell
          mediaElementStyle="p-2"
          latestLog={latestLog}
          media={media}
        />
      ) : (
        <td className="p-2 pl-5">--</td>
      )}

      <td className="p-2 text-right">
        <Hamburger>
          <button
            className="dropdown-item cursor-pointer"
            type="button"
            data-toggle="modal"
            data-target={`#${FormDataTargets.setupMonitoringForm}`}
            onClick={openSetupMonitoringFormFunction}
          >
            {"Edit monitoring settings"}
          </button>
          <button
            className="dropdown-item cursor-pointer"
            onClick={() =>
              dispatch(cancelSiteMonitoring(campaignId, cityId, settingId))
            }
            disabled={!settings || !isActive}
          >
            {"Cancel site monitoring"}
          </button>
        </Hamburger>
      </td>
    </>
  );
}

function SetupMonitorCell({ selectedSite }) {
  const dispatch = useDispatch();

  return (
    <>
      <td></td>
      <td></td>
      <td></td>
      <td className="p-2 text-right align-middle">
        <button
          type="button"
          className="btn btn-link p-0 shadow-none"
          disabled={!selectedSite}
          data-toggle="modal"
          data-target={`#${FormDataTargets.setupMonitoringForm}`}
          onClick={() => dispatch(openSetupMonitoringForm())}
        >
          Setup Monitoring
        </button>
      </td>
    </>
  );
}

/**
 * Monitoring Campaign CityView Table ROW -------------------------------------
 */
function MonitoringCampaignCityViewTableRow({ item }) {
  const { media, settings } = item;
  const { campaignId, id: mediaId } = media;

  // Selector state
  const selectedSite = useSelector(
    (state) => state.monitoringSettings.selectedSites[mediaId]
  );

  // Redirect Url
  const monitorMediaLogPageUrl = RedirectTo.monitorMediaLogPageUrl
    .replace(":campaignId", campaignId)
    .replace(":mediaId", mediaId);

  return (
    <tr>
      <td>
        <SelectionCell
          mediaId={mediaId}
          siteSelected={selectedSite}
          selectSite={selectMediaSite}
          unSelectSite={unselectMediaSite}
        />
      </td>
      <InfoCell
        mediaElementStyle="p-2"
        media={media}
        redirectUrl={monitorMediaLogPageUrl}
      />

      {settings ? (
        <MediaSettingsDetailsCells item={item} />
      ) : (
        <SetupMonitorCell selectedSite={selectedSite} />
      )}
    </tr>
  );
}

export default MonitoringCampaignCityViewTableRow;
