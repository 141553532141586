// Utils and Constants
import { constructLineChartData } from "../../../utils/ChartUtils";
import {
  getIsSubscriptionActive,
  IsTrafficOtsMethodology,
  maskCharacter,
} from "../../../common-utils/subscription-utils/SubscriptionUtil";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import { DurationConstants } from "../../../constants/GeneralConstants";
import { checkIsSellerUser } from "../../../utils/SellerUtils";

//Components
import { LineChart } from "../../../components/charts/Charts";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";
import { MonthlyEstImpressionTable } from "../media-info/MonthlyEstImpressionTable";

// Page Section
function HourlyTrafficDataSection({ media = {}, roadSegmentTrafficInfo = {} }) {
  // if No-Traffic-Data available for Site
  if (Object.keys(roadSegmentTrafficInfo).length === 0) {
    return (
      <>
        <AlertMessage
          className="justify-content-center p-3"
          textMessage="Traffic Data is not available for this Site."
          isLoading={false}
        />
        <hr className="divider"></hr>
      </>
    );
  }

  const otsSummary = media.otsSummary || {};
  const trafficData = roadSegmentTrafficInfo.properties?.speedProfile;

  const chartTrafficData = constructLineChartData(trafficData);

  const { otsLit, ots, durationDays } = otsSummary;
  // Converting impression for 30-Days
  const monthlyOtsLit = (otsLit / durationDays) * DurationConstants.THIRTY_DAYS;
  const monthlyOts = (ots / durationDays) * DurationConstants.THIRTY_DAYS;

  // Checking Org is Subscribed or Not and is it seller
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();
  const isTrafficMethodology = IsTrafficOtsMethodology();

  // preparing data for "Estimate-Impression-Table"
  const impressionInfo = [
    {
      title: "Avg.",
      otsLit:
        !isSubscribed && isSeller
          ? maskCharacter(monthlyOtsLit)
          : toLocaleString(monthlyOtsLit),
      ots:
        !isSubscribed && isSeller
          ? maskCharacter(monthlyOts)
          : toLocaleString(monthlyOts),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-6">
          <h3 className="mb-3">{"Hourly traffic"}</h3>
          {/* hourly traffic chart */}
          <LineChart data={chartTrafficData} />
        </div>

        {/* Est Monthly Impressions */}
        {isTrafficMethodology && (
          <MonthlyEstImpressionTable
            title={"Average Monthly Impressions Estimates"}
            className={"col-6 pr-0"}
            impressionInfo={impressionInfo}
          />
        )}
      </div>
      <hr className="divider"></hr>
    </>
  );
}

export default HourlyTrafficDataSection;
