import { downloadFile, sendCreateOrUpdateRequest } from "../utils/api/apiUtils";
import {
  campaignMediaUploadUrl,
  campaignSiteUploadUrl,
  getCampaignTemplateUrl,
} from "../urls/SiteUploadURL";

// download template
export async function downloadTemplateData() {
  const downloadUrl = getCampaignTemplateUrl;
  return downloadFile(downloadUrl);
}

// upload-template
export async function uploadExcelTemplate(formData, isMonitoring) {
  // Request Params
  const params = {
    monitoring: isMonitoring,
  };

  return sendCreateOrUpdateRequest(
    campaignSiteUploadUrl,
    formData,
    true,
    "POST",
    {},
    params
  );
}

// upload-template)add-media) from "campaign-context" (from city-summary-page)
export async function uploadMediaFileFromCampaignContext(
  campaignId,
  formData,
  isMonitoring
) {
  // Request Params
  const params = {
    monitoring: isMonitoring,
  };

  return sendCreateOrUpdateRequest(
    campaignMediaUploadUrl.replace(":campaignId", campaignId),
    formData,
    true,
    "POST",
    {},
    params
  );
}
