import { toast } from "react-toastify";
import { all, put, select, takeLatest } from "redux-saga/effects";

// APIs
import {
  cancelSiteMonitoringFn,
  createSetupMonitoringSettings,
  editSiteMonitoringSettings,
  getMonitoringCampSummaryData,
  getMonitoringSettingsByMedia,
  getMonitoringSummaryByCity,
} from "../../apis/MonitoringSettingsAPI";

// Constants and Utils
import { Monitoring } from "../../constants/action-constants/MonitoringActionConstants";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import { getErrorMessage } from "../../utils/util";

// Monitoring Campaign-Summary
export function* getMonitoringCampaignSummary(action) {
  try {
    const { id } = action.payload;

    // making GET request for monitoring campaign-summary
    const summaryResponse = yield getMonitoringCampSummaryData(id);

    yield put({
      type: Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY_SUCCESS,
      payload: { summaryResponse },
    });
  } catch (error) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(error);

    yield put({
      type: Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

// Monitoring City Summary
export function* getMonitoringCitySummary(action) {
  try {
    const {
      campaignId,
      cityId,
      pageNumber,
      pageSizeCount,
      keyword,
      applyFilters = false,
    } = action.payload;

    let filters = [];
    if (applyFilters) {
      filters = yield select((state) =>
        Object.keys(state.filter.appliedFilters).reduce((acc, eachKey) => {
          if (!state.filter.appliedFilters[eachKey]) {
            return acc;
          }
          acc.push(eachKey);
          return acc;
        }, [])
      );
    }

    // making GET request for monitoring city-summary
    const responseCitySummary = yield getMonitoringSummaryByCity(
      campaignId,
      cityId,
      pageNumber,
      pageSizeCount,
      keyword,
      filters
    );

    yield put({
      type: Monitoring.GET_MONITORING_CITY_SUMMARY_SUCCESS,
      payload: { responseCitySummary },
    });
  } catch (error) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(error);

    yield put({
      type: Monitoring.GET_MONITORING_CITY_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

// Monitoring Media Settings
export function* getMonitoringMediaSettings(action) {
  try {
    const { campaignId, mediaId } = action.payload;

    // making GET request for "media-settings"
    const responseSettings = yield getMonitoringSettingsByMedia(
      campaignId,
      mediaId
    );

    yield put({
      type: Monitoring.GET_MONITORING_MEDIA_SETTING_SUCCESS,
      payload: { responseSettings },
    });
  } catch (error) {
    yield put({
      type: Monitoring.GET_MONITORING_MEDIA_SETTING_FAILURE,
      payload: error,
    });
  }
}

// Create Setup-Monitoring Form Saga
export function* createSetupMonitoring(action) {
  const { inputFieldBean, campaignId, cityId, mediaIds } = action.payload;

  // converting "start-date" to timestamp
  const startFullDate = inputFieldBean.startTimestamp;
  const STS = new Date(startFullDate).getTime();

  // converting "end-date" to timestamp
  const endFullDate = inputFieldBean.endTimestamp;
  const ETS = new Date(endFullDate).getTime();

  // updating "timestamps" (STS=startTimeStamp, ETS=endTimeStamp)
  inputFieldBean.startTimestamp = STS;
  inputFieldBean.endTimestamp = ETS;

  // Edit Site Monitoring Key ("for edit-site-monitoring-settings")
  // this key is used to call different APIs (create/edit monitoring)
  // --------------------------------------------------------------------
  const monitorMediaSettingId = yield select(
    (state) => state.monitoringSettings.monitorMediaSettingId
  );

  // making call for create-setup-monitoring
  try {
    const responseMsg = monitorMediaSettingId
      ? yield editSiteMonitoringSettings(inputFieldBean, campaignId, mediaIds)
      : yield createSetupMonitoringSettings(
          inputFieldBean,
          campaignId,
          mediaIds
        );

    yield put({
      type: Monitoring.CREATE_SETUP_MONITORING_SUCCESS,
    });

    // below two actions are called to "reset media checkbox selection" and
    // to get updated "monitoring-city-summary"
    yield all([
      put({
        type: Monitoring.RESET_SELECTION,
      }),
      put({
        type: Monitoring.GET_MONITORING_CITY_SUMMARY,
        payload: { campaignId, cityId },
      }),
    ]);
    toast.success(responseMsg);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Monitoring.CREATE_SETUP_MONITORING_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
    return;
  }
}

// Cancel SIte Monitoring
export function* cancelSiteMonitoring(action) {
  try {
    const { campaignId, cityId, settingId } = action.payload;

    // making PUT request for "Cancel-Media-Monitoring"
    const cancelMsg = yield cancelSiteMonitoringFn(settingId);

    yield put({
      type: Monitoring.CANCEL_SITE_MONITORING_SUCCESS,
    });

    // below two actions are called to "reset media checkbox selection" and
    // to get updated "monitoring-city-summary"
    yield all([
      put({
        type: Monitoring.RESET_SELECTION,
      }),
      put({
        type: Monitoring.GET_MONITORING_CITY_SUMMARY,
        payload: { campaignId, cityId },
      }),
    ]);
    toast.success(cancelMsg);
  } catch (error) {
    yield put({
      type: Monitoring.CANCEL_SITE_MONITORING_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY,
      getMonitoringCampaignSummary
    ),
    takeLatest(
      Monitoring.GET_MONITORING_CITY_SUMMARY,
      getMonitoringCitySummary
    ),
    takeLatest(
      Monitoring.GET_MONITORING_MEDIA_SETTING,
      getMonitoringMediaSettings
    ),
    takeLatest(Monitoring.CREATE_SETUP_MONITORING, createSetupMonitoring),
    takeLatest(Monitoring.CANCEL_SITE_MONITORING, cancelSiteMonitoring),
  ]);
}
