import { useSelector } from "react-redux";

// Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { calEstPrice } from "../../utils/PriceFactorUtil";
import { getStretchImpressions } from "../../utils/campaignPlanningUtil";
import { StretchSelectionButton } from "./PlanningUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";

// page Components
function Loader() {
  return (
    <tr>
      <td
        colSpan={4}
        className="d-flex align-items-center justify-content-center"
      >
        <Spinner />
        <span className="ml-2">Please wait, Road Stretches are Loading...</span>
      </td>
    </tr>
  );
}

function ProgressBar({ baseOts, currentOts, className }) {
  const width = currentOts ? (currentOts / baseOts) * 100 : 0;
  const widthPecentage = width + "%";

  return (
    <span
      className={`progress progress-bar ${className}`}
      role="progressbar"
      style={{ width: widthPecentage }}
      aria-valuenow={width}
      aria-valuemin="0"
      aria-valuemax="100"
    ></span>
  );
}

function ImpressionInfoCell({ stretchInfo, baseOts, dayCount }) {
  const {
    otsAvg,
    otsLitAvg,
    genericOtsLit,
    genericOts,
    targetOtsLit,
    targetOts,
  } = stretchInfo;

  const estImpressions = otsLitAvg ?? otsAvg;

  // for TG-specific-impressions
  const genericImpressions = genericOtsLit ?? genericOts;
  const specificImpressions = targetOtsLit ?? targetOts;

  if (genericImpressions || specificImpressions) {
    return (
      <td className="col-6">
        <div className="row no-gutters">
          <span className="col-2 px-0">{"Tg Imp: "}</span>
          <span
            className="col-3 px-0"
            title={toStringWithRoundUp(dayCount * specificImpressions)}
          >
            {specificImpressions
              ? toStringWithRoundUp(dayCount * specificImpressions)
              : "NA"}
          </span>
          <div className="col-7">
            <ProgressBar
              baseOts={baseOts}
              currentOts={specificImpressions}
              className={"bg-success"}
            />
          </div>
        </div>

        <div className="row no-gutters">
          <span className="col-2 px-0">{"Gen Imp: "}</span>
          <span className="col-3 px-0">
            {genericImpressions
              ? toStringWithRoundUp(dayCount * genericImpressions)
              : "NA"}
          </span>
          <div className="col-7">
            <ProgressBar baseOts={baseOts} currentOts={genericImpressions} />
          </div>
        </div>
      </td>
    );
  }

  return (
    <td className="col-6">
      <div className="row no-gutters">
        <span className="col-2 px-0">{"Gen Imp: "}</span>
        <span className="col-3 px-0">
          {estImpressions
            ? toStringWithRoundUp(dayCount * estImpressions)
            : "NA"}
        </span>
        <div className="col-6">
          <ProgressBar baseOts={baseOts} currentOts={estImpressions} />
        </div>
      </div>
    </td>
  );
}

function StretchInfoCell({ stretchInfo, dayCount }) {
  const { name } = stretchInfo;
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(stretchInfo);

  // Total Impression ==> After selecting TG
  if (specificImpressions) {
    const totalImpressions = specificImpressions + genericImpressions;
    return (
      <td className="col-3">
        <p className="mb-0 text-truncate stretch-info-cell" title={name}>
          {name}
        </p>
        <span className="sub-text">
          Total Imp:{" "}
          {totalImpressions
            ? toStringWithRoundUp(dayCount * totalImpressions)
            : "NA"}
        </span>
      </td>
    );
  }

  // Total Impressions ==> Before selecting TG
  return (
    <td className="col-3">
      <p className="mb-0 text-truncate stretch-info-cell" title={name}>
        {name}
      </p>
      <span className="sub-text">
        Total Imp:{" "}
        {genericImpressions
          ? toStringWithRoundUp(dayCount * genericImpressions)
          : "NA"}
      </span>
    </td>
  );
}

function EstimatedPriceCell({ stretchInfo, dayCount }) {
  const { minOperatingPriceAvg: mop, occupancyAvg: occupancy } = stretchInfo;
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(stretchInfo);

  const genPriceFactor = useSelector((state) => state.campaignPlanning.genCpm);
  const tgPriceFactor = useSelector((state) => state.campaignPlanning.tgCpm);

  const estimatePriceBean = {
    genericImpressions,
    specificImpressions,
    mop,
    occupancy,
    dayCount,
    tgPriceFactor,
    genPriceFactor,
  };
  const { genericCost, specificCost } = calEstPrice(estimatePriceBean);
  const totalEstCost = specificCost ? genericCost + specificCost : genericCost;

  return (
    <td className="col-2">
      <p className="mb-0">{toStringWithRoundUp(mop ? mop : "NA")}</p>
      <p className="mb-0">
        {toStringWithRoundUp(totalEstCost ? totalEstCost : "NA")}
      </p>
    </td>
  );
}

function RoadStretchTableRow({ stretchInfo, baseOts, dayCount }) {
  return (
    <tr>
      {/* strech info cell */}
      <StretchInfoCell stretchInfo={stretchInfo} dayCount={dayCount} />

      {/* impression info cell */}
      <ImpressionInfoCell
        stretchInfo={stretchInfo}
        baseOts={baseOts}
        dayCount={dayCount}
      />

      {/* estimated price cell */}
      <EstimatedPriceCell stretchInfo={stretchInfo} dayCount={dayCount} />

      {/* strech selection button */}
      <StretchSelectionButton stretchInfo={stretchInfo} />
    </tr>
  );
}

function RoadStretchTableBody({
  roadStretchData,
  baseOts,
  roadStretchLoading,
}) {
  // Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  if (roadStretchLoading) {
    return <Loader />;
  }

  // RoadStretch data is empty return null
  if (roadStretchData.length < 1) {
    return null;
  }

  return (
    <>
      {roadStretchData.map((stretchInfo) => (
        //Road strech table row
        <RoadStretchTableRow
          key={stretchInfo.id}
          stretchInfo={stretchInfo}
          dayCount={duration}
          baseOts={baseOts}
        />
      ))}
    </>
  );
}

/**
 * Road Strech Table
 */
function RoadStretchTable({ roadStretchData, baseOts, roadStretchLoading }) {
  return (
    <table className="table">
      <tbody>
        {/* Road strech table body */}
        <RoadStretchTableBody
          roadStretchData={roadStretchData}
          baseOts={baseOts}
          roadStretchLoading={roadStretchLoading}
        />
      </tbody>
    </table>
  );
}

export default RoadStretchTable;
