import { LocationSummary } from "../../constants/action-constants/LocationSummaryActionConstants";

//intital state
const initialState = {
  //location summary
  locationSummaryInfoLoading: false,
  locationSummaryInfo: "",
  locationSummaryInfoErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LocationSummary.GET_LOCATION_SUMMARY:
      return {
        ...state,
        locationSummaryInfoLoading: true,
      };

    case LocationSummary.GET_LOCATION_SUMMARY_SUCCESS: {
      return {
        ...state,
        locationSummaryInfo: action.payload,
        locationSummaryInfoLoading: false,
      };
    }

    case LocationSummary.GET_LOCATION_SUMMARY_FAILURE: {
      return {
        ...state,
        locationSummaryInfoLoading: false,
        locationSummaryInfoErr: action.payload,
      };
    }

    case LocationSummary.RESET_LOCATION_SUMMARY: {
      return initialState;
    }

    default:
      return state;
  }
};
