import moment from "moment";
import { useState } from "react";

// Constants
import { FormDataTargets } from "../../constants/GeneralConstants";

// Components
import BootstrapDateRangePicker from "../bootstrap-date-range-picker/BootstrapDateRangePicker";

// Page Constants
const monitorFrequency = [
  { key: "1", value: "Daily" },
  { key: "2", value: "Once In 2 Days" },
  { key: "3", value: "Once In 3 Days" },
  { key: "7", value: "Weekly Once" },
];

// Page Components
function PersonalDetails({
  setContactName,
  setContactEmail,
  setContactMobile,
}) {
  const personContactDetails = [
    {
      displayTag: "Name",
      type: "text",
      placeholder: " Name of the person who leads the monitoring",
      setValueFunction: setContactName,
      inputBoxStyle: "rounded-lg",
    },

    {
      displayTag: "Email Id",
      type: "staticEmail",
      placeholder: " E.g. joe@email.com",
      setValueFunction: setContactEmail,
      inputBoxStyle: "rounded-lg",
    },

    {
      displayTag: "Phone No.",
      type: "number",
      placeholder: " (+91) (xxxxxxxxxx)",
      setValueFunction: setContactMobile,
      inputBoxStyle: "rounded-lg",
    },
  ];

  return (
    <>
      {personContactDetails.map((info, i) => (
        <div key={i} className="form-group row">
          <label className="col-3 col-form-label">{info.displayTag}</label>
          <div className="col-9">
            <input
              className={`form-control shadow-none ${info.inputBoxStyle}`}
              placeholder={info.placeholder}
              value={info.value}
              onChange={({ target }) => info.setValueFunction(target.value)}
            />
          </div>
        </div>
      ))}
    </>
  );
}

function MonitoringDetails({ dateRange, setFrequencyDays, setDateRange }) {
  // Function
  function onApplyDates(selectedDate) {
    const { startDate, endDate } = selectedDate;
    setDateRange({
      ...dateRange,
      startTimestamp: startDate,
      endTimestamp: endDate,
    });
  }

  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Monitoring Details"}</label>
      <div className="col row mx-0">
        {/* Frequency */}
        <select
          className="form-control shadow-none col-4 rounded-lg"
          placeholder="Frequency"
          id={"frequencyDays"}
          onChange={(e) => setFrequencyDays(e.target.value)}
        >
          {monitorFrequency.map((obj) => (
            <option key={obj.key} value={obj.key}>
              {obj.value}
            </option>
          ))}
        </select>

        {/* Date Range Picker */}
        <BootstrapDateRangePicker
          initialStartDate={dateRange.startTimestamp}
          initialEndDate={dateRange.endTimestamp}
          onApplyDates={onApplyDates}
          minDate={new Date()}
          styleObject={{
            buttonClassName: "col px-0 ml-2",
            border: true,
          }}
        />
      </div>
    </div>
  );
}

function MonitoringInstructions({ setInstructions }) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Notes"}</label>
      <div className="col-9">
        <textarea
          className="form-control shadow-none rounded-lg"
          rows={3}
          placeholder=" Enter notes for monitoring person"
          onChange={({ target }) => setInstructions(target.value)}
        ></textarea>
      </div>
    </div>
  );
}

/**
 * Form- Model
 */
function MediaSetupMonitoringForm({
  onFormSubmit,
  defaultDateRange = {},
  settings = {}, // initial state (if Edit Monitoring)
}) {
  // States
  const [contactName, setContactName] = useState(settings.contactName || "");
  const [contactEmail, setContactEmail] = useState(settings.contactEmail || "");
  const [contactMobile, setContactMobile] = useState(
    settings.contactMobile || ""
  );
  const [frequencyDays, setFrequencyDays] = useState(
    settings.frequencyDays || "1"
  );
  const [instructions, setInstructions] = useState(settings.instructions || "");

  // if component has "monitoringMediaDateObj" as parameter,
  // then the default date will be "mediaDates" else "TodayDate"
  const [dateRange, setDateRange] = useState({
    startTimestamp: defaultDateRange?.startTimestamp
      ? new Date(defaultDateRange?.startTimestamp)
      : new Date(),
    endTimestamp: defaultDateRange?.endTimestamp
      ? new Date(defaultDateRange?.endTimestamp)
      : moment(new Date()).add(1, "days"), // adding one day to todays date (atleast one day monitoring)
  });

  // Input Field Bean to pass on Submit Form
  const inputFieldBean = {
    contactName: contactName,
    contactEmail: contactEmail,
    contactMobile: contactMobile,
    frequencyDays: frequencyDays,
    startTimestamp: dateRange.startTimestamp,
    endTimestamp: dateRange.endTimestamp,
    instructions: instructions,
  };

  return (
    <div
      className="modal fade"
      id={FormDataTargets.setupMonitoringForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">Monitoring Settings</h4>
          </div>

          <div className="modal-body px-5">
            {/* "PersonalDetails" component is for first 3 FormInputs */}
            <PersonalDetails
              setContactName={setContactName}
              setContactEmail={setContactEmail}
              setContactMobile={setContactMobile}
            />

            {/* monitoring details */}
            <MonitoringDetails
              dateRange={dateRange}
              setFrequencyDays={setFrequencyDays}
              setDateRange={setDateRange}
            />

            {/* monitoring instructions */}
            <MonitoringInstructions setInstructions={setInstructions} />
          </div>

          {/* form Submit and Cancel Button */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3"
              data-dismiss="modal"
            >
              {"Cancel"}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-action mt-2"
              onClick={() => onFormSubmit(inputFieldBean)}
              data-dismiss="modal"
            >
              {"Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MediaSetupMonitoringForm;
