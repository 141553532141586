import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Marker, Popup } from "react-leaflet";

// Actions
import {
  downloadMonitoringMediaLogPdfReport,
  getMonitoringMediaLog,
  openMonitoringMediaLogImageModal,
} from "../../../actions/campaign-monitoring/MonitoringLogActions";
import { getMonitoringMediaSettings } from "../../../actions/campaign-monitoring/MonitoringSettingsActions";
import {
  getMediaDetails,
  uploadFileItems,
} from "../../../actions/campaign-media/CampaignMediaActions";

// Constants and Utils
import {
  DATE_FORMATS,
  FormDataTargets,
  ImageFileTypes,
  MapZoom,
  MediaColor,
  PageSize,
} from "../../../constants/GeneralConstants";
import {
  constructDateString,
  constructSingleDateString,
  getTimeInHoursAndMinutes,
} from "../../../common-utils/date-utils/DateUtils";
import { getMediaIcon } from "../../../utils/MediaUtils";
import { selectFile } from "../../../common-utils/file-utils/FileUtils";

// Component
import Spinner from "../../../components/spinner/Spinner";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import { CustomButton } from "../../../mavin/components/button/Button";
import { DefaultImage } from "../../../components/campaign-media-table-row/MediaRow";
import Pagination from "../../../components/pagination/Pagination";
import MonitoringMediaLogImageModal from "./MonitoringMediaLogImageModal";
import {
  Dimensions,
  PriceInfo,
} from "../../../mavin/components/media-info/MediaInfo";

// CSS
import "../CampaignMonitoring.scss";

// Page Constants
const frequencyDaysObject = {
  1: "Daily",
  2: "Once In 2 Days",
  3: "Once In 3 Days",
  7: "Weekly Once",
};

// Page Components
function DownloadReport({ campaignId, mediaId }) {
  const dispatch = useDispatch();

  // Selector
  const downloadingPdfReport = useSelector(
    (state) => state.monitoringLog.downloadingMonitorPdfReport
  );

  //Checks for downloading report
  if (downloadingPdfReport) {
    return <Spinner />;
  }

  return (
    <p
      className="text-primary cursor-pointer download-report"
      onClick={() =>
        dispatch(downloadMonitoringMediaLogPdfReport(campaignId, mediaId))
      }
      data-html2canvas-ignore="true"
    >
      {"Download Monitoring Report"}
    </p>
  );
}

const LogImage = ({ imageDetail }) => {
  const dispatch = useDispatch();

  const imageUrl = imageDetail.originalUrl;
  if (!imageUrl) {
    return <DefaultImage />;
  }
  return (
    <img
      className="log-record-image"
      src={imageUrl}
      alt="image"
      data-toggle="modal"
      data-target={`#${FormDataTargets.mediaLogImageForm}`}
      onClick={() => dispatch(openMonitoringMediaLogImageModal({ imageUrl }))}
    />
  );
};

function RecordContainer({ log, index }) {
  const { images = [], createdOn } = log;
  const dateString = constructSingleDateString(
    createdOn,
    DATE_FORMATS.full_month_with_date_year
  );

  const time = getTimeInHoursAndMinutes(createdOn);

  return (
    <div className="border-bottom p-2">
      <span>{`${time} , ${dateString} | Record #${index}`}</span>
      <div className="d-flex mt-2">
        {images.map((imageDetail) => (
          <LogImage imageDetail={imageDetail} key={imageDetail.originalUrl} />
        ))}
      </div>
    </div>
  );
}

function MediaLogsRecordSection({ mediaLogs, pagination }) {
  const { id, mediaId } = useParams();
  // Selector state
  const mediaLogLoading = useSelector(
    (state) => state.monitoringLog.monitoringMediaLogLoading
  );

  const dispatch = useDispatch();
  const { totalCount, pageNo, pageSize } = pagination;
  const recordCounter = totalCount - (pageNo - 1) * pageSize;

  function loadMonitoringMediaLog(pageNumber, pageSizeCount) {
    dispatch(getMonitoringMediaLog(id, mediaId, pageNumber, pageSizeCount));
  }

  if (mediaLogLoading) {
    return (
      <div className="d-flex justify-content-center bg-alt mt-5 border p-2">
        <Spinner />
        <span className="mt-1 px-2">
          {"Media log records are loading, Please wait..."}
        </span>
      </div>
    );
  }

  return (
    <>
      <h3 className="mt-5">{totalCount} Records Found</h3>
      <hr className="divider mb-0"></hr>
      {mediaLogs.map((log, index) => (
        <RecordContainer log={log} index={recordCounter - index} key={log.id} />
      ))}

      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={loadMonitoringMediaLog}
      />
    </>
  );
}
function LogMap({ media = {} }) {
  const zoom = MapZoom.zoomLevel10;
  const { latitude, longitude } = media;
  const position = [latitude, longitude];
  const campaignMediaIcon = getMediaIcon(MediaColor.campaign);

  return (
    <>
      {latitude && longitude && (
        <LLMap center={position} zoom={zoom} tile="transport">
          <Marker position={position} icon={campaignMediaIcon}>
            <Popup>
              <b>{media.title}</b>
            </Popup>
          </Marker>
        </LLMap>
      )}
    </>
  );
}

// media image section
function MediaImageSection({ mediaId, campaignId }) {
  const dispatch = useDispatch();

  // selector state
  const fileItems = useSelector((state) => state.campaignMedia.fileItems);

  const isLoading = useSelector(
    (state) => state.campaignMedia.fileItemsLoading
  );

  // upload function
  async function selectAndUpload() {
    const files = await selectFile(false, ImageFileTypes);
    dispatch(uploadFileItems(campaignId, mediaId, files));
  }

  // if image is loading
  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spinner />
      </div>
    );
  }

  const imageUrl = fileItems[0]?.originalUrl;

  // if image is present
  if (imageUrl) {
    return <img className="h-100 w-100" src={imageUrl}></img>;
  }

  return (
    <div className="d-flex align-items-center justify-content-center flex-column h-100">
      <i className="far fa-images fa-5x opacity-25 text-primary mb-3"></i>
      <p className="mb-3">
        <strong>No site image available</strong>
      </p>
      <CustomButton
        displayContent="Upload Images"
        onClickFunction={selectAndUpload}
      />
    </div>
  );
}

function LogMediaImageWithMap({ media, campaignId }) {
  // destructuring media id
  const { id } = media;

  return (
    <div className="d-flex col px-0 media-image-map-container">
      <div className="col-6 px-0">
        <MediaImageSection mediaId={id} campaignId={campaignId} />
      </div>
      <div className="col-6 px-0">
        <LogMap media={media} />
      </div>
    </div>
  );
}

function MediaSettingsSection({ mediaSettings = {}, campaignId, mediaId }) {
  // if no-media-settings present then return below text.
  if (Object.keys(mediaSettings).length === 0) {
    return (
      <div className="col p-4 text-center">
        <h4>Media Monitoring is not assigned to anyone.</h4>
      </div>
    );
  }

  const {
    frequencyDays: frequencyDaysInCount,
    startTimestamp,
    endTimestamp,
    updatedOn,
    monitorUser,
  } = mediaSettings;

  const frequencyDaysInString = frequencyDaysObject[frequencyDaysInCount];

  // Time Frame
  const dateString = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const lastUpdateTime = constructSingleDateString(
    updatedOn,
    DATE_FORMATS.full_month_with_date_year
  );

  const monitoringSettingsInfo = [
    {
      label: "Frequency",
      value: frequencyDaysInString,
    },
    {
      label: "TimeFrame",
      value: dateString,
    },
    {
      label: "Last Updated Time",
      value: lastUpdateTime,
    },
  ];

  return (
    <div className="col p-4">
      <div className="d-flex justify-content-between">
        <h5 className="pt-2">{"Monitoring Settings"}</h5>
        {/* Download report */}
        <DownloadReport campaignId={campaignId} mediaId={mediaId} />
      </div>
      <div className="my-4">
        <span>{"Contact"}</span>
        <h3 className="mb-0">{monitorUser?.name}</h3>
        <span>
          {monitorUser?.emailId} | +91 {monitorUser?.phoneNo}
        </span>
      </div>

      <div className="d-flex justify-content-between my-4">
        {monitoringSettingsInfo.map((info, i) => (
          <div key={i}>
            <span>{info.label}</span>
            <span className="h4 d-block">{info.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function MonitoringMediaDetails({ media, mediaSettings, campaignId }) {
  return (
    <>
      <h1>{media.title}</h1>
      <p className="h3">
        <Dimensions media={media} />
        <PriceInfo media={media} /> | {media.region} |
        {` Geo Coordinates : ${media.latitude},${media.longitude}`}
      </p>
      <div className="d-flex border">
        {/* Media-Image and MapView */}
        <LogMediaImageWithMap media={media} campaignId={campaignId} />

        {/* monitor-user-information */}
        <MediaSettingsSection
          mediaSettings={mediaSettings}
          campaignId={campaignId}
          mediaId={media.id}
        />
      </div>
    </>
  );
}

/**
 * Main Page
 */
function MonitoringMediaLogViewPage() {
  const { id, mediaId } = useParams();

  // Selector State
  // getting "media-log-details"
  const mediaLogDetails =
    useSelector((state) => state.monitoringLog.monitoringMediaLog) || {};
  const mediaLogs = mediaLogDetails?.items;
  const pagination = mediaLogDetails?.pagination || {};

  // getting "media-details" and "city-id"
  const mediaDetailsLoading = useSelector(
    (state) => state.campaignMedia.mediaDetailsLoading
  );
  const media =
    useSelector((state) => state.campaignMedia.media.mediaDetails) || {};

  // getting "monitor-media-settings"
  const mediaSettings =
    useSelector((state) => state.monitoringSettings.mediaSettings) || {};

  // monitoring log image modal
  const openMonitoringMediaLogImageModal = useSelector(
    (state) => state.monitoringLog.openModal
  );

  // monitoring log image url
  const { imageUrl } = useSelector(
    (state) => state.monitoringLog.processDataObject
  );

  const pageNumber = 1,
    pageSizeCount = PageSize.MonitoringMediaLog;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMediaDetails(id, mediaId));
    dispatch(getMonitoringMediaSettings(id, mediaId));
    dispatch(getMonitoringMediaLog(id, mediaId, pageNumber, pageSizeCount));
  }, [dispatch, id, mediaId, pageSizeCount]);

  // component loading
  if (mediaDetailsLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper" id="cont-media-log">
      {/* Page- Content */}
      <div className="page-content">
        {/* Media and MonitoringUser Details */}
        <MonitoringMediaDetails
          media={media}
          mediaSettings={mediaSettings}
          campaignId={id}
        />

        {/* Latest Log Records */}
        <MediaLogsRecordSection mediaLogs={mediaLogs} pagination={pagination} />

        {/* MODALS */}
        {openMonitoringMediaLogImageModal && (
          <MonitoringMediaLogImageModal imageUrl={imageUrl} />
        )}
      </div>
    </div>
  );
}

export default MonitoringMediaLogViewPage;
