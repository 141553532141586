// STORE ALL THE REDIRECT-UI URLS HERE
export const RedirectTo = {
  // Home
  homeUrl: "/home",

  // Campaign List
  campaignListPageUrl: "/campaign/list",

  // Login
  loginPageUrl: "/login",
  passwordResetUrl: "/password-reset",
  forgotPasswordUrl: "/forgot-password",

  // Help Content
  helpOtsPageUrl: "/help/ots",
  helpLtsPageUrl: "/help/lts",
  helpCovidChangePageUrl: "/help/covid-change",
  helpSiteApproxPageUrl: "/help/site-approximation",
  helpDefinitionPageUrl: "/help/terminology-definition",

  // Org
  orgProfileUrl: "/org/profile",
  orgUserListUrl: "/org/user/list",
  orgUserCreateUrl: "/org/user/create",
  orgUserManageUrl: "/org/user/:userId",
  orgSubscriptionUrl: "/org/subscription",

  orgTargetGroupListUrl: "/org/target-group/list",
  orgTargetGroupCreateUrl: "/org/target-group/create", //url to create
  orgTargetGroupUpdateUrl: "/org/target-group/update/:targetGroupId", //url to update
  orgTargetGroupViewPageUrl: "/org/target-group/:targetGroupId",

  orgRoadStretchListUrl: "/org/road-stretch/list",
  orgRoadStretchCreateUrl: "/org/road-stretch/create",
  orgRoadStretchViewPageUrl: "/org/road-stretch/:stretchId",

  orgDataLayerListUrl: "/org/data-layer/list",
  orgDataLayerCreateUrl: "/org/data-layer/create",
  orgDataLayerViewPageUrl: "/org/data-layer/:dataLayerId",

  orgMediaPricePageUrl: "/org/media-price",

  // Org Template
  orgTemplateWithMapUrl: "/org/template/map",

  // Campaign Report
  campaignCreatePageUrl: "/site-upload",
  campaignViewPageUrl: "/campaign/:campaignId/summary",
  campaignCityViewPageUrl: "/campaign/:campaignId/summary/:cityId",
  campaignMediaViewPageUrl: "/campaign/:campaignId/media/:mediaId",
  campaignMapViewPageUrl: "/campaign/:campaignId/:cityId/map",

  // Campaign Planning - MAVIN
  campaignPlanSetupPageUrl: "/campaign/plan/setup",
  campaignPlanOverviewPageUrl: "/campaign/:campaignId/plan/overview",
  campaignPlanCityViewPageUrl: "/campaign/:campaignId/plan/city/:cityId",
  campaignPlanMapViewPageUrl: "/campaign/:campaignId/plan/:cityId/map",

  // Monitoring
  monitorCampaignCreatePageUrl: "/monitor/settings/excel-uploads",
  monitorCampaignViewPageUrl: "/monitor/settings/campaign/:campaignId/summary",
  monitorCityViewPageUrl: "/monitor/settings/campaign/:campaignId/city/:cityId",
  monitorMediaLogPageUrl: "/monitor/log/campaign/:campaignId/media/:mediaId",
  monitorSelectionViaMapUrl: "/monitor/settings/selection-via-map",
  mediaSiteSelectionPageUrl: "/home/p1",

  // Tools
  toolsDashboardUrl: "/tools",
  toolsSiteAnalyserPageUrl: "/tools/site-analyser",
  toolsOtsPageUrl: "/tools/ots",
  toolsLtsPageUrl: "/tools/lts",
  toolsDemographicPageUrl: "/tools/demographics",
  toolsReachAndFrequencyPageUrl: "/tools/reach-and-frequency",
  toolsCompareLocationsPageUrl: "/tools/compare-locations",
  toolsCityAnalyserPageUrl: "/tools/city-analyser/:regionId",
  toolsTargetGroupOtsPageUrl: "/tools/ots-target-group",

  // Seller pages
  sellerInventoryHomeUrl: "/seller/inventory",
  sellerInventoryCityViewPageUrl: "/seller/inventory/city/:cityId",
  sellerInventoryMapViewPageUrl: "/seller/inventory/city/:cityId/map",
  sellerEditMediaPageUrl: "/seller/:sellerId/inventory/media/:mediaId/edit",
  sellerMediaDetailPageUrl: "/seller/:sellerId/inventory/media/:mediaId",

  // Seller Public Website
  sellerWebsitePreviewPageUrl: "/seller/website/preview",
  sellerWebsiteSetupPageUrl: "/seller/website/setup",
  sellerInventoryUpload: "/seller/inventory/upload",
};
