import React from "react";
import { useDispatch } from "react-redux";

// Actions
import { openSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";
import { openLocationOfIntrestForm } from "../../../mantaray/actions/map-view/LocationOfIntrestActions";

// Utils and Constants
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Tabs and Sections
import PointsOfInterestTab from "../../map-view/points-of-interest/PointsOfInterestTab";
import RoadStretchesTab from "../../map-view/road-stretches/RoadStretchesTab";
import SecTab from "../../map-view/sec/SecTab";
import TargetGroupTab from "../../map-view/target-group/TargetGroupTab";
import BoundariesTab from "../../map-view/boundaries/BoundariesTab";
import DataLayersTab from "../../map-view/data-layers/DataLayersTab";
import ExploreSitesSection from "../../map-view/media-sites/ExploreSitesSection";

// Constants
const contents = [
  <>
    <i className="fas fa-map-marker-alt my-2 text-primary"></i>
    <p className="pb-0">Inventory Media sites</p>
  </>,
  <>
    <i className="far fa-compass my-2 text-primary"></i>
    <p className="pb-0">Points of Interest</p>
  </>,
  <>
    <i className="fas fa-border-all my-2 text-primary"></i>
    <p className="pb-0">Boundaries</p>
  </>,
  <>
    <i className="fas fa-users my-2 text-primary"></i>
    <p className="pb-0">Target group</p>
  </>,
  <>
    <i className="fas fa-border-none my-2 text-primary"></i>
    <p className="pb-0">SEC</p>
  </>,
  <>
    <i className="fas fa-road my-2 text-primary"></i>
    <p className="pb-0">Road Stretches</p>
  </>,
  <>
    <i className="fas fa-layer-group my-2 text-primary"></i>
    <p className="pb-0">Data Layers</p>
  </>,
];

const listingViewLabels = {
  header: {
    title: "Media Inventory",
    createBtnText: "",
    createBtnUrl: "",
  },

  search: {
    placeholder: "Search media sites from inventory",
    fields: ["title"],
  },
};

// Component
function SelectedContent({ selectedTab, cityId, bbox }) {
  switch (selectedTab) {
    case 1:
      return <InventoryMediaSitesTab cityId={cityId} />;
    case 2:
      return <PointsOfInterestTab cityId={cityId} />;
    case 3:
      return <BoundariesTab cityId={cityId} bbox={bbox} />;
    case 4:
      return <TargetGroupTab bbox={bbox} />;
    case 5:
      return <SecTab cityId={cityId} bbox={bbox} />;
    case 6:
      return <RoadStretchesTab cityId={cityId} />;
    case 7:
      return <DataLayersTab cityId={cityId} />;
    default:
      return null;
  }
}

/**
 * Inventory Media Sites TAB
 */
function InventoryMediaSitesTab({ cityId }) {
  return (
    <div className="map-tab">
      <div className={"map-tab-listing"}>
        {/* Header Section */}
        <div className="navbar p-0">
          <h2>{listingViewLabels.header.title}</h2>
        </div>
        <hr className="divider my-2"></hr>

        {/* Explore Sites Section */}
        <ExploreSitesSection
          listingViewLabels={listingViewLabels}
          cityId={cityId}
        />
      </div>
    </div>
  );
}

function SelectCityButton({ name = "" }) {
  const dispatch = useDispatch();
  return (
    <div
      title={name}
      className="tab-item"
      data-toggle="modal"
      data-target={`#${FormDataTargets.singleCitySelectionForm}`}
      onClick={() => dispatch(openSingleCitySelectionForm())}
    >
      <i className="fas fa-city my-2 text-primary"></i>
      <p className="pb-0 text-truncate">{name ? name : "Select City"}</p>
    </div>
  );
}

function CityAnalyserMapTabsSection({ selectedTab, tabOnClick, regionsData }) {
  const dispatch = useDispatch();

  const { name, bbox, id: cityId = "" } = regionsData;

  const productId = process.env.REACT_APP_PRODUCT_ID;

  return (
    <div className="map-tabs-cont top-0">
      <div className="tab-items">
        {/* Select city button Tab */}
        <SelectCityButton name={name} />

        {/* Other Tabs */}

        {productId && (
          <div
            className="tab-item text-primary"
            data-toggle="modal"
            data-target={`#${FormDataTargets.locationOfIntrestForm}`}
            onClick={() => dispatch(openLocationOfIntrestForm())}
            disabled={!cityId}
          >
            <i className="fas fa-map-marker-alt my-2"></i>
            <p className="pb-0">Location Of Interest</p>
          </div>
        )}
        {contents.map((content, index) => (
          <div
            key={index}
            className={`tab-item ${selectedTab === index + 1 ? "active" : ""}`}
            onClick={() => tabOnClick(index + 1)}
            disabled={!cityId}
          >
            {content}
          </div>
        ))}
      </div>

      {!!selectedTab && (
        <div className="tab-cont bg-white">
          <SelectedContent
            selectedTab={selectedTab}
            cityId={cityId}
            bbox={bbox}
          />
        </div>
      )}
    </div>
  );
}

export default CityAnalyserMapTabsSection;
