import { ExploreSites } from "../../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";

//initial state
const initialState = {
  selectedSites: {},
  selectedSitesLoading: {},
  error: "",
};

function multipleSitesAction(ids, select = true) {
  const selectedSites = {};
  for (const id of ids) {
    selectedSites[id] = select;
  }
  return selectedSites;
}

export default (state = initialState, action) => {
  switch (action.type) {
    // add explore site
    //----------------------------------------------------------------------
    case ExploreSites.ADD_EXPLORE_SITE: {
      const { mediaId } = action.payload.campaignMediaInfo;
      return {
        ...state,
        selectedSitesLoading: {
          ...state.selectedSitesLoading,
          [mediaId]: true,
        },
      };
    }

    case ExploreSites.ADD_EXPLORE_SITE_SUCCESS: {
      const updatedSelectedSitesLoading = { ...state.selectedSitesLoading };
      delete updatedSelectedSitesLoading[action.payload.mediaId];
      return {
        ...state,
        selectedSitesLoading: { [action.payload.mediaId]: false },
      };
    }

    case ExploreSites.ADD_EXPLORE_SITE_FAILURE: {
      const updatedSelectedSitesLoading = { ...state.selectedSitesLoading };
      delete updatedSelectedSitesLoading[action.payload.mediaId];
      return {
        ...state,
        selectedSitesLoading: updatedSelectedSitesLoading,
        error: action.payload.err,
      };
    }

    case ExploreSites.EXPLORE_SITES_SELECT_MULTIPLE:
      return {
        ...state,
        selectedSites: {
          ...state.selectedSites,
          ...multipleSitesAction(action.payload.ids, true),
        },
      };

    case ExploreSites.EXPLORE_SITES_UNSELECT_MULTIPLE:
      return {
        ...state,
        selectedSites: {
          ...state.selectedSites,
          ...multipleSitesAction(action.payload.ids, false),
        },
      };

    case ExploreSites.RESET_EXPLORE_SITES_SELECTION:
      return initialState;

    default:
      return state;
  }
};
