import { Link } from "react-router-dom";

// Utils
import { constructRedirectPath } from "./redirect-utils/RediectUtil";

export function ManageMonitoringCell({
  isMonitoringEnabled,
  className = "",
  redirectUrl,
  target = "",
}) {
  if (!isMonitoringEnabled) {
    return <td className={`text-muted ${className}`}>Monitoring Not Active</td>;
  }

  return (
    <td className={className}>
      <Link to={constructRedirectPath(redirectUrl)} target={target}>
        {"Manage monitoring"}
      </Link>
    </td>
  );
}

export function MonitoringStatus({ isMonitoringEnabled }) {
  if (!isMonitoringEnabled) {
    return <p className="mb-0 text-muted">Monitoring Not active</p>;
  }

  return (
    <p className="text-success font-weight-bold mb-0 ">
      <span>
        <i className="fa fa-circle mr-1"></i>
      </span>
      Monitoring
    </p>
  );
}
