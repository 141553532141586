/**
 * User Dashboard
 */
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { getAllCampaigns } from "../../actions/campaign/CampaignActions";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Contants and Utils
import { PageSize } from "../../constants/GeneralConstants";

// Components
import DashboardCard from "../../components/dashboard-card/DashboardCard";
import CampaignsTable from "../../components/campaign-list-table/CampaignsTable";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import SearchInput from "../../components/search-input/SearchInput";

// Page Constants
const {
  campaignPlanSetupPageUrl,
  campaignCreatePageUrl,
  monitorCampaignCreatePageUrl,
  toolsDashboardUrl,
  campaignListPageUrl,
} = RedirectTo;

// labels
const TXT_PLACEHOLDER_SEARCH = "Search Campaign";

const cardsInfo = [
  {
    title: "Campaign Manager",
    content:
      "Manage your campaign end to end from creation, analysis and monitoring.",
    CTALabel: "Create Campaign",
    CTALink: campaignPlanSetupPageUrl,
  },
  {
    title: "Campaign Analyser",
    content: "Analyze multiple sites as once and view their cumulative impact.",
    CTALabel: "Upload site list",
    CTALink: campaignCreatePageUrl,
  },
  {
    title: "Monitor Campaign",
    content:
      "Manage your campaign monitoring by assigning Jobs and image upload logs",
    CTALabel: "Setup Monitoring",
    CTALink: monitorCampaignCreatePageUrl,
  },
  {
    title: "Quick Tools",
    content:
      "Tools to analyze the key performance indicators and their cumulative impact.",
    CTALabel: "Tools Dashboard",
    CTALink: toolsDashboardUrl,
  },
];

// Page Components
// ----------------------------------------------------------------------------
function HomeDashboardCards() {
  const cards = cardsInfo;
  return (
    <div className="justify-content-left row">
      {cards.map((card, i) => {
        return <DashboardCard key={i} card={card} i={i} />;
      })}
    </div>
  );
}

function TableActionSection({ onClickSearch, onSearchTextChange }) {
  return (
    <div className="d-flex justify-content-between">
      <h4>{"Recent reports"}</h4>
      <div className="d-flex align-items-baseline">
        <Link className="mx-3" to={campaignListPageUrl}>
          {"Show All Campaigns"}
        </Link>
        <SearchInput
          placeholder={TXT_PLACEHOLDER_SEARCH}
          onClickSearch={onClickSearch}
          onTextChange={onSearchTextChange}
        />
      </div>
    </div>
  );
}

/**
 * HOME PAGE
 */
function HomePage() {
  const dispatch = useDispatch();

  // State
  const [searchText, setSearchText] = useState("");

  // Selector States
  const campaigns =
    useSelector((state) => state.campaign.campaigns.items) || [];
  const pagination =
    useSelector((state) => state.campaign.campaigns.pagination) || {};
  const isCampaignsPresent = campaigns.length;
  const loading = useSelector((state) => state.campaign.loading);

  const pageNumber = 1;
  const pageSizeCount = PageSize.campaignList;

  // Dispatch
  useEffect(() => {
    dispatch(getAllCampaigns("", pageNumber, pageSizeCount));
  }, [dispatch, pageSizeCount]);

  const pageActions = (
    <>
      <p className="pt-2">Version : {process.env.REACT_APP_VERSION}</p>
      {/* <p>Last updated Thursday, March 20, 2021, 10:45 PM</p> */}
    </>
  );

  // Functions
  function onClickSearch() {
    dispatch(getAllCampaigns(searchText, pageNumber, pageSizeCount));
  }
  function onSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <div className="content-wrapper bg-alt">
      <PageHeader
        title={"Dashboard"}
        border={false}
        shadow={false}
        actions={pageActions}
      />

      <div className="page-content container-fluid">
        <HomeDashboardCards />
        <hr className="divider" />

        <div className="card card-reports shadow mt-5">
          <div className="card-body ">
            <TableActionSection
              onClickSearch={onClickSearch}
              onSearchTextChange={onSearchTextChange}
            />

            <CampaignsTable
              campaigns={campaigns}
              isCampaignsPresent={isCampaignsPresent}
              loading={loading}
              pagination={pagination}
              searchText={searchText}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
