import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import {
  getClosestRoadSegments,
  resetClosestRoadSegments,
} from "../../actions/org/OrgRoadStretchActions";
import { clearSegmentOtsInfo } from "../../actions/mavin-tools/OtsToolActions";
import {
  clearTgInfo,
  getTargetGroups,
  getTgInfo,
} from "../../actions/org/OrgTargetGroupActions";
import {
  clearTgSpecificOtsForSegments,
  getTgSpecificOtsForSegments,
} from "../../prooh/actions/campaign-planning/CampaignPlanningActions";
import {
  closeTargetGroupForm,
  openTargetGroupForm,
} from "../../prooh/actions/campaign-planning/TargetGroupFormActions";

// Utils and Constants
import {
  LocationSearchInput,
  MapViewWithPindropAndRoadSelect,
  RouteIdSection,
} from "./ToolsUtils";
import { NoSegmentsMessageSection } from "../org/OrgUtils";
import {
  formatText,
  toLocaleString,
} from "../../common-utils/string-utils/StringUtils";
import { defaultPagination } from "../../constants/UrlConstants";
import {
  DurationConstants,
  FormDataTargets,
} from "../../constants/GeneralConstants";

// Components
import PageHeader from "../../mavin/components/page-header/PageHeader";
import { ButtonWithLoader } from "../../mavin/components/button/Button";
import Spinner from "../../components/spinner/Spinner";
import TableHeaders from "../../components/table/TableHeaders";
import TargetGroupForm from "../../components/target-group-form/TargetGroupForm";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "",
      className: "col-4 py-1",
    },
  },
  {
    title: {
      displayName: "Lit",
      className: "col-4 py-1",
    },
  },
  {
    title: {
      displayName: "Not Lit",
      className: "col-4 py-1",
    },
  },
];

// Page Components
function SelectTgButton() {
  const dispatch = useDispatch();

  const selectedTgName = useSelector((state) => state.orgTargetGroup.tgName);
  const tgInfoLoading = useSelector(
    (state) => state.orgTargetGroup.tgInfoLoading
  );

  return (
    <div className="d-flex justify-content-center">
      <button
        type="button"
        className="btn border rounded-lg shadow-none dropdown-toggle"
        data-toggle="modal"
        data-target={`#${FormDataTargets.targetGroupForm}`}
        onClick={() => dispatch(openTargetGroupForm())}
      >
        {selectedTgName ? selectedTgName : "Select TG"}
      </button>
      {tgInfoLoading && <Spinner className="m-2 spinner-border-sm" />}
    </div>
  );
}

function SelectTgAndGetOtsSection({ selectedSegment, targetGroupId }) {
  const dispatch = useDispatch();

  const { id: segmentId } = selectedSegment;

  //tg Splitted Ots loader
  const segmentTgOtsLoading = useSelector(
    (state) => state.tgSpecificOts.segmentTgOtsLoading
  );

  // Function
  function getTgSpecifiOtsForSegmentsFn() {
    if (!targetGroupId) {
      toast.error("Please select Target Group");
      return;
    }
    dispatch(getTgSpecificOtsForSegments(targetGroupId, [segmentId]));
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center py-3">
        {/* Route ID */}
        <RouteIdSection id={segmentId} />

        {/* select TG */}
        <SelectTgButton />

        {/* Submit Request Button */}
        <ButtonWithLoader
          displayContent={"Get OTS Split"}
          onClickFunction={getTgSpecifiOtsForSegmentsFn}
          loader={segmentTgOtsLoading}
          isDisabled={segmentTgOtsLoading}
        />
      </div>
      <hr className="divider my-0 row"></hr>
    </>
  );
}

function SegmentOtsSplittedTableRow({ otsInfo }) {
  const { title, ots, otsLit } = otsInfo;

  return (
    <tr>
      <td>{title}</td>
      <td>{formatText(toLocaleString(otsLit))}</td>
      <td>{formatText(toLocaleString(ots))}</td>
    </tr>
  );
}

function SegmentOtsSplittedTable({ segmentTgSplittedOts }) {
  const { ots, otsLit, targetOts, targetOtsLit, genericOts, genericOtsLit } =
    segmentTgSplittedOts;

  // Create Ots-Content for Row
  const otsTableContent = [
    {
      title: "Total Impressions",
      otsLit: otsLit,
      ots: ots,
    },
    {
      title: "TG Specific Impressions",
      otsLit: targetOtsLit,
      ots: targetOts,
    },
    {
      title: "Generic Impressions",
      otsLit: genericOtsLit,
      ots: genericOts,
    },
  ];

  return (
    <div className="table-responsive mt-4">
      <h4>Impressions ({DurationConstants.THIRTY_DAYS} Days)</h4>

      <table className="table table-media">
        {/* Table header */}
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        <tbody>
          {/* Table rows */}
          {otsTableContent.map((otsInfo, i) => (
            <SegmentOtsSplittedTableRow otsInfo={otsInfo} key={i} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function SelectedTgInfo({ selectedTgInfo }) {
  const { poiTypesMap, targetGroup: targetGroupInfo } = selectedTgInfo;

  const { resultLayers = [] } = targetGroupInfo;

  // Resulting Poi Layers Map
  const resPoiLayersMap = resultLayers.reduce((acc, eachLayer) => {
    const poiTypeId = eachLayer?.poiTypeId;
    const poiTypeName = poiTypesMap[poiTypeId]?.name;
    acc[poiTypeId] = poiTypeName;
    return acc;
  }, {});

  // resulting poi layers
  const resultingPoiLayersName = Object.values(resPoiLayersMap);

  return (
    <div className="my-3">
      <h4>{"Resulting POI Layers"}</h4>
      <ul className="mb-0">
        {resultingPoiLayersName.map((eachPoi, index) => (
          <li key={index} className="p-1">
            <span>{eachPoi}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

/**
 * Page : Target Group OTS Profiling
 */
function TargetGroupOtsProfilingToolPage() {
  const dispatch = useDispatch();
  // State
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});

  const { id: segmentId } = selectedSegment;

  // all closest road-segments
  const roadSegments =
    useSelector((state) =>
      Object.values(state.orgRoadStretch.roadSegmentsMap)
    ) || [];

  // for "TG setup-Form"
  const openTgSetupForm = useSelector(
    (state) => state.targetGroupFormModal.openModal
  );

  // Selected Tg Info
  const selectedTgInfo = useSelector((state) => state.orgTargetGroup.tgInfo);
  const targetGroupId = selectedTgInfo?.targetGroup?.id || "";

  // Splitted Ots
  const segmentTgSplittedOts =
    useSelector(
      (state) => state.tgSpecificOts.allSegmentsTgOtsMap[segmentId]
    ) || {};

  // Pagination
  const pageNo = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  // Reset Ots info
  useEffect(() => {
    setSelectedSegment({});
    dispatch(resetClosestRoadSegments());
    dispatch(clearTgInfo());
    dispatch(closeTargetGroupForm());
    dispatch(clearTgSpecificOtsForSegments());
  }, [dispatch, locationStr]);

  useEffect(() => {
    dispatch(getTargetGroups(false, "", pageNo, pageSize));
  }, [dispatch]);

  useEffect(() => {
    // showing toaster to select road segment
    if (roadSegments.length > 0) {
      toast.info("Please select the Road to get Ots Split");
    }
    // when ever location change we are clearing previous closest road segment
    // and fetch new closest road segment. so length is changing
    // if the roadSegments length change useEffect will get trigger and shows toaster message
  }, [roadSegments.length]);

  // Functions
  // This call-back function is used to pick the lat-lng and
  // fetch all closest road-segments
  function getCoordinatesFromPinDrop(locationData) {
    const coordinateString = `${locationData.lat},${locationData.lng}`;
    setLocationStr(coordinateString);
    setCoordinate([locationData.lat, locationData.lng]);
    dispatch(getClosestRoadSegments(coordinateString));
  }

  function onPolylineClick(e, eachSegment) {
    setSelectedSegment({});
    dispatch(clearSegmentOtsInfo());
    setSelectedSegment(eachSegment);
    e.originalEvent.view.L.DomEvent.stopPropagation(e);
  }

  // Submit TG Function
  function tgSubmitFn(tgId) {
    // clear the previous tgInfo
    dispatch(clearTgInfo());

    // clearing previous tgspecific ots
    dispatch(clearTgSpecificOtsForSegments());

    if (tgId) {
      // get tgInfo
      dispatch(getTgInfo(tgId));
    }
  }

  return (
    <>
      <div className="content-wrapper map-content-wrapper">
        {/* Map Left Section */}
        <div className="map-layout-left">
          <PageHeader
            title={"Impressions (OTS) - Target Group Profiling"}
            shadow={false}
          />
          <hr className="divider my-3 row"></hr>

          {/* Left Content */}
          <LocationSearchInput
            locationStr={locationStr}
            setLocationStr={setLocationStr}
            setCoordinate={setCoordinate}
          />

          {/* No RoadSegments Message */}
          <NoSegmentsMessageSection
            roadSegments={roadSegments}
            locationStr={locationStr}
          />

          {/* Selected Segement Info Section */}
          {Object.keys(selectedSegment).length > 0 && (
            <SelectTgAndGetOtsSection
              selectedSegment={selectedSegment}
              targetGroupId={targetGroupId}
            />
          )}

          {/* Splitted Ots Table */}
          {Object.keys(segmentTgSplittedOts).length > 0 && (
            <>
              <SegmentOtsSplittedTable
                segmentTgSplittedOts={segmentTgSplittedOts}
              />

              {/* Selected TG info */}
              <SelectedTgInfo selectedTgInfo={selectedTgInfo} />
            </>
          )}
        </div>

        {/* MapView Section */}
        <MapViewWithPindropAndRoadSelect
          coordinate={coordinate}
          selectedSegment={selectedSegment}
          getCoordinatesFromPinDrop={getCoordinatesFromPinDrop}
          onPolylineClick={onPolylineClick}
        />
      </div>

      {/* Modals */}
      {/* TG-selection-form */}
      {openTgSetupForm && (
        <TargetGroupForm
          segmentIds={[selectedSegment.id]}
          dispatchFn={tgSubmitFn}
        />
      )}
    </>
  );
}

export default TargetGroupOtsProfilingToolPage;
