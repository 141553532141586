import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getTgHeatMapData, removeTgHeatMapData } from "../../actions/geo-data/GeoDataActions";

// Constants and Utils
import { FormDataTargets } from "../../constants/GeneralConstants";

// Page Functions

/**
 *filters the tgtiles with tgId if tgId is present,otherwise returns all tgTitles
 * @param {*} tgTitles=> array of objects, each object containing id and label info
 * @param {*} tgId=>string
 * @return =>array
 */
function getFinalisedTgsInfo(tgTitles, tgId) {
  // if tgId is present
  if (tgId) {
    return tgTitles.filter((eachTitle) => eachTitle.id === tgId);
  }

  return tgTitles;
}

// Page Component
function SearchTgInputSection({ tgTitles, setTgId }) {
  // State
  const [suggestedTgs, setSuggestedTgs] = useState([]);

  // Search Tg From Input Function
  function searchTargetGroupFn(e) {
    const inputText = e.target.value;
    if (inputText.length <= 2) {
      return setSuggestedTgs([]);
    }

    const suggestion = tgTitles.filter((tgObj) => tgObj.label.toLowerCase().includes(inputText.toLowerCase()));
    setSuggestedTgs(suggestion);
  }

  // function after selecting TG from suggestions
  function suggestedTgSelectFn(eachTg) {
    setTgId(eachTg.id);
    setSuggestedTgs([]);
  }

  return (
    <>
      <input
        type="search"
        className={`form-control rounded-lg`}
        placeholder={"Search Or Select Target Group"}
        aria-label={"placeholder"}
        aria-describedby="btn-search"
        onChange={(e) => searchTargetGroupFn(e)}
      />

      {/* Suggested TGs section */}
      {suggestedTgs.length > 0 && (
        <div className="border rounded-lg mt-1 bg-alt">
          {suggestedTgs.slice(0, 5).map((eachTg) => (
            <li key={eachTg.id} className="dropdown-item cursor-pointer" onClick={() => suggestedTgSelectFn(eachTg)}>
              {eachTg.label}
            </li>
          ))}
        </div>
      )}
    </>
  );
}

function TgListingSection({ tgTitles, tgId, setTgId, selectedTgId }) {
  const finalSelectedTgId = tgId ? tgId : selectedTgId;
  return (
    <div className="mt-2">
      {tgTitles.map((tg) => (
        <div key={tg.id} className="d-flex justify-content-between border-top p-2">
          <p className="mb-0">{tg.label}</p>
          <div className="pr-3">
            {tg.id === finalSelectedTgId ? (
              <button className="btn btn-outline-success add-button shadow-none" onClick={() => setTgId("")}>
                <i className="fa fa-check mr-1"></i>
                <b>{"Selected"}</b>
              </button>
            ) : (
              <button className="btn btn-outline-primary add-button shadow-none" onClick={() => setTgId(tg.id)}>
                <b>{"Select"}</b>
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

/**
 * Target-Group Form for Selecting TG
 */
function TargetGroupForm({ existingTgId = "", segmentIds, dispatchFn }) {
  // State
  const [tgId, setTgId] = useState("");

  // Selector State
  const tgTitles = useSelector((state) => state.orgTargetGroup.tgTitles) || [];

  const finalTgTitles = getFinalisedTgsInfo(tgTitles, existingTgId);

  //tg selected id
  const selectedTgId = useSelector((state) => state.tgSpecificOts.tgId);

  // for TG Heatmap
  const { bbox = "" } = useSelector((state) => state.campaignPlanning.regionsData);

  const tgInfo = useSelector((state) => state.orgTargetGroup.tgInfo);

  const { targetGroup = {} } = tgInfo;
  const { resultLayers = [] } = targetGroup;

  const resPoiLayers = resultLayers.map((eachLayer) => eachLayer.poiTypeId);

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    // this is to prevent additional calls for HeatMap
    if (segmentIds?.length > 0) {
      return;
    }

    // clear the old selected tg data
    dispatch(removeTgHeatMapData());

    // getting TG-Heatmap data
    dispatch(getTgHeatMapData(resPoiLayers, bbox));
  }, [dispatch, JSON.stringify(resPoiLayers)]);

  function cancelTgFormFn() {
    setTgId("");
  }

  return (
    <div className="modal fade" id={FormDataTargets.targetGroupForm} data-backdrop="static">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">{"Target Group"}</h4>
          </div>

          {/* Form Body */}
          <div className="modal-body form-body px-5">
            <SearchTgInputSection tgTitles={finalTgTitles} setTgId={setTgId} />
            <TgListingSection tgTitles={finalTgTitles} tgId={tgId} setTgId={setTgId} selectedTgId={selectedTgId} />
          </div>

          {/* Form Footer */}
          {/* TODO: Use Buttons components here */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3 rounded-lg"
              data-dismiss="modal"
              onClick={cancelTgFormFn}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-action mt-2 rounded-lg"
              data-dismiss="modal"
              onClick={() => dispatchFn(tgId)}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TargetGroupForm;
