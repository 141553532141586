export const getMonitoringCampaignSummaryUrl =
  "/api/v3/monitor/settings/campaign/:campaignId/summary";

export const getMonitoringSummaryByCityUrl =
  "/api/v3/monitor/settings/campaign/:campaignId/summary/city/:cityId";

export const getMonitoringSettingByMediaUrl =
  "/api/v3/monitor/settings/campaign/:campaignId/media/:mediaId";

export const createSetupMonitoringSettingsUrl =
  "/api/v3/monitor/settings/campaign/:campaignId/media?ids=:mediaIds";

export const cancelSiteMonitoringUrl =
  "/api/v3/monitor/settings/cancel/:settingId";

export const editMonitoringSettingsUrl =
  "/api/v3/monitor/settings/campaign/:campaignId/media?ids=:mediaIds";
