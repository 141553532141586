import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { getLocationSummary } from "../../../actions/location-summary/LocationSummaryActions";

// Utils
import { getPoiDistribution } from "./LocationSummaryUtil";

//Components
import SocioEconomicIndicators from "./SocioEconomicIndicators";
import Landmarks from "./Landmarks";
import LocationSummaryPieChart from "./LocationSummaryPieChart";
import Population from "./Population";
import RadiusSelector from "./RadiusSelector";
import AlertMessage from "../alert-message/AlertMessage";

/**
 * Location Summary Section
 */
export default function LocationSummary({ latitude, longitude }) {
  const dispatch = useDispatch();
  //State
  const [radius, setRadius] = useState(1000);

  //Selector
  const locationSummaryInfo = useSelector(
    (state) => state.locationSummary.locationSummaryInfo
  );
  const isLoading = useSelector(
    (state) => state.locationSummary.locationSummaryInfoLoading
  );

  //Dispatch
  useEffect(() => {
    dispatch(getLocationSummary({ latitude, longitude, distance: radius }));
  }, [dispatch, latitude, longitude, radius]);

  //Checks for data
  if (!locationSummaryInfo) {
    return (
      <AlertMessage
        className="justify-content-center p-3"
        textMessage="Location Summary is not available for this Site."
        isLoading={false}
      />
    );
  }

  const {
    poiCounts = {},
    population,
    sustenance,
    realtySale,
    nearByPois = {},
  } = locationSummaryInfo;

  const poiDistribution = getPoiDistribution(poiCounts);

  return (
    <>
      {/* Title And Radius Selector */}
      <RadiusSelector
        title={"Location Summary Within"}
        onItemSelect={setRadius}
        isLoading={isLoading}
        className="py-2"
      />

      <div className="row">
        {/* Pie-Chart */}
        <LocationSummaryPieChart
          className="col-6"
          poiDistribution={poiDistribution}
        />

        <div className="col-6">
          {/* Population info */}
          <Population population={population} />
          <hr className="divider"></hr>

          {/* Socio-economic indicators */}
          <SocioEconomicIndicators
            sustenance={sustenance}
            realtySale={realtySale}
          />
          <hr className="divider"></hr>

          {/* Landmarks */}
          <Landmarks nearByPois={nearByPois} />
          <hr className="divider"></hr>
        </div>
      </div>
    </>
  );
}
