import React from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import {
  closeMonitoringMediaLogImageModal,
  downloadMonitoringMediaLogImage,
} from "../../../actions/campaign-monitoring/MonitoringLogActions";

// Constants
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Components
import {
  ButtonWithLoader,
  CustomButton,
} from "../../../mavin/components/button/Button";

/**
 * To download and show the  monitoring media log image in modal
 * @param {*} imageUrl =>url of image
 */
export default function MonitoringMediaLogImageModal({ imageUrl }) {
  const dispatch = useDispatch();

  // Selector state
  const isImageDownloading = useSelector(
    (state) => state.monitoringLog.downloadMonitoringMediaLogImage
  );

  return (
    <div
      className="modal fade"
      id={FormDataTargets.mediaLogImageForm}
      data-backdrop="static"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {/* modal  header */}
          <div className="modal-header bg-alt">
            <h5 className="modal-title">Media Log Image</h5>
          </div>

          {/* modal body  */}
          <div className="modal-body">
            <img className="media-log-image" src={imageUrl} />
          </div>

          {/* modal footer */}
          <div className="modal-footer">
            <div>
              <ButtonWithLoader
                displayContent="Download"
                className="mr-2"
                loader={isImageDownloading}
                onClickFunction={() => {
                  dispatch(downloadMonitoringMediaLogImage(imageUrl));
                }}
              />
              <CustomButton
                onClickFunction={() => {
                  dispatch(closeMonitoringMediaLogImageModal());
                }}
                data-dismiss="modal"
                displayContent="Close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
