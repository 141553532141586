import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import {
  cancelSiteMonitoringUrl,
  createSetupMonitoringSettingsUrl,
  editMonitoringSettingsUrl,
  getMonitoringCampaignSummaryUrl,
  getMonitoringSettingByMediaUrl,
  getMonitoringSummaryByCityUrl,
} from "./../urls/MonitoringSettingsURL";

export async function getMonitoringCampSummaryData(id) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getMonitoringCampaignSummaryUrl.replace(":campaignId", id);
  return sendGetRequest(url, {}, true, headerParams);
}

export async function getMonitoringSummaryByCity(
  campaignId,
  cityId,
  pageNumber,
  pageSizeCount,
  keyword,
  types
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    pn: pageNumber,
    ps: pageSizeCount,
  };

  if (keyword) {
    params.kw = keyword;
  }
  if (types) {
    params._type = types;
  }

  const url = getMonitoringSummaryByCityUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);
  return sendGetRequest(url, params, true, headerParams);
}

export async function getMonitoringSettingsByMedia(campaignId, mediaId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getMonitoringSettingByMediaUrl
    .replace(":campaignId", campaignId)
    .replace(":mediaId", mediaId);
  return sendGetRequest(url, {}, true, headerParams);
}

export async function createSetupMonitoringSettings(
  inputFieldBean,
  campaignId,
  mediaIds
) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    createSetupMonitoringSettingsUrl
      .replace(":campaignId", campaignId)
      .replace(":mediaIds", mediaIds),
    inputFieldBean,
    true,
    "POST",
    headerParams
  );
}

export async function cancelSiteMonitoringFn(settingId) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    cancelSiteMonitoringUrl.replace(":settingId", settingId),
    {},
    true,
    "PUT",
    headerParams
  );
}

export async function editSiteMonitoringSettings(
  inputFieldBean,
  campaignId,
  mediaIds
) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    editMonitoringSettingsUrl
      .replace(":campaignId", campaignId)
      .replace(":mediaIds", mediaIds),
    inputFieldBean,
    true,
    "PUT",
    headerParams
  );
}
