//Utils
import { downloadFile, sendGetRequest } from "./api/apiUtils";

// URLS
import { restoreMapStateUrl } from "../urls/map-view/SaveMapStateURL";

// TODO : Once the API for fetching mapViewImage changes, refactor this
export async function getMapImage(campaignId, cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = restoreMapStateUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);
  return sendGetRequest(url, {}, true, headerParams);
}

export async function downloadImage(imageUrl) {
  return downloadFile(imageUrl);
}
