// Constants and Utils
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  getIsOrgSeller,
  getIsSubscriptionActive,
  maskCharacter,
} from "../../../common-utils/subscription-utils/SubscriptionUtil";
import { UrlKeys } from "../../../constants/GeneralConstants";
import {
  getFrequencyValue,
  getModifiedPopulation,
  getReachPercentage,
  getReachValueAsString,
} from "../../../utils/ReachAndFrequencyUtils";
import { checkIsSellerUser } from "../../../utils/SellerUtils";
import { useUrlKeyCheck } from "../../utils/hooks/HooksUtil";

// Components
import Rating from "./Rating";

export function MonthlyImpressions({ impressions, duration }) {
  // Checking Org is Subscribed or Not
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();
  const { ots, tgOts } = impressions;

  //Checking if it is from Site-analyser
  const isFromSiteAnalyser = useUrlKeyCheck(UrlKeys.siteAnalyser);

  const finalTotalImpressionsToShow =
    !isSubscribed && isSeller
      ? maskCharacter(ots)
      : formatText(toLocaleString(ots));

  const finalTgImpressionsToShow =
    !isSubscribed && isSeller
      ? maskCharacter(tgOts)
      : formatText(toLocaleString(tgOts));

  return (
    <div className="col-2">
      <p>Impressions ({duration} Days)</p>
      {!isFromSiteAnalyser && <h5>Tg : {finalTgImpressionsToShow}</h5>}
      <h5>Total : {finalTotalImpressionsToShow}</h5>
    </div>
  );
}

export function MediaReach({ reachFrequency = {}, cityPopulation }) {
  const { reach, tgReach } = getReachValueAsString(reachFrequency);
  const reachPercentage = getReachPercentage(cityPopulation, reachFrequency);
  const { tgReachPercent } = reachFrequency || {};

  // Checking Org is Subscribed or Not and is Org Type is Seller
  const isSubscribed = getIsSubscriptionActive();
  const isSeller = checkIsSellerUser();

  //Checking if it is from Site-analyser
  const isFromSiteAnalyser = useUrlKeyCheck(UrlKeys.siteAnalyser);

  const finalTgreach =
    !isSubscribed && isSeller
      ? `(*%)`
      : tgReachPercent
      ? tgReachPercent.toFixed(2)
      : "";

  return (
    <div className="col-2 pl-0">
      <p>
        <span>{"Reach (%)"}</span>
      </p>
      {!isFromSiteAnalyser && (
        <h5>
          Tg : {formatText(tgReach)} {finalTgreach ? `(${finalTgreach}%)` : ""}
        </h5>
      )}
      <h5>
        Total : {formatText(reach)} {reachPercentage}
      </h5>
    </div>
  );
}

export function CityPopulation({ cityPopulation }) {
  const population = getModifiedPopulation(cityPopulation);
  return (
    <div className="col-2 pl-0">
      <p>
        <span className="d-block">{"City Population"}</span>
      </p>
      <h5>{population}</h5>
    </div>
  );
}

export function MediaFrequency({ reachFrequency }) {
  const frequencyValue = getFrequencyValue(reachFrequency);
  return (
    <div className="col-1 pl-0">
      <p>{"Frequency"}</p>
      <h5>{frequencyValue}</h5>
    </div>
  );
}

export function AvgDwellTime({ dwellTimeSummary }) {
  const { averageTime } = dwellTimeSummary ?? {};
  return (
    <div className="col-1 px-0">
      <p>Avg. Dwell Time</p>
      <h5>
        {averageTime ? Math.floor(averageTime) : formatText(averageTime)} sec
      </h5>
    </div>
  );
}

export function LtsRating({ ltsRating }) {
  return (
    <div className="col-1">
      <p>LTS rating</p>
      <h5>{formatText(ltsRating)}</h5>
    </div>
  );
}

// ArAC ==> Ar(AspectRation), A(Area), C(Clutter)
export function ArAC_ratings({ ltsSummary }) {
  const {
    areaIndex,
    clutterIndex,
    aspectRatioIndex,
    elevationIndex,
    tiltIndex,
  } = ltsSummary || {};

  const ltsInfoConstants = [
    {
      label: "Aspect ratio",
      value: aspectRatioIndex,
    },
    {
      label: "Area",
      value: areaIndex,
    },
    {
      label: "Clutter",
      value: clutterIndex,
    },
    {
      label: "Elevation",
      value: elevationIndex,
    },
    {
      label: "Tilt",
      value: tiltIndex,
    },
  ];

  return (
    <div className="col-3 px-0">
      {ltsInfoConstants.map((eachInfo) => (
        <div key={eachInfo.label} className="mb-2">
          <Rating displayName={eachInfo.label} value={eachInfo.value} />
        </div>
      ))}
    </div>
  );
}
