import { useDispatch, useSelector } from "react-redux";
import { Marker, Popup } from "react-leaflet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// Actions
import { selectExploreSite } from "../../../actions/map-view/media-sites/ExploreSitesSelectionActions";
import { removeMediaSite } from "../../../actions/map-view/media-sites/MediaSitesActions";

// Utils & Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { getMediaOts } from "../../../utils/mavinMetrics";
import { MapView, UrlKeys } from "../../../constants/GeneralConstants";
import {
  getIsSubscriptionActive,
  maskCharacter,
} from "../../../common-utils/subscription-utils/SubscriptionUtil";
import { checkIsSellerUser } from "../../../utils/SellerUtils";

// Components
// TODO:: Move this "DefaultImage" component out from "MediaRow"
import { DefaultImage } from "../../campaign-media-table-row/MediaRow";
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";
import Spinner from "../../spinner/Spinner";

// Default value
const DEFAULT_PROPS_TO_SHOW = {
  dimensions: true,
  image: true,
  lts: true,
  ots: true,
  title: true,
};

// Functions
function getMediaMarkerPopupText(media = {}, propsToShow) {
  const text = [];
  const { ots, tgOts } = getMediaOts(media) || {};
  const { title, height, width, ltsSummary = {} } = media;

  // TITLE
  if (propsToShow[MapView.MetaData.TITLE]) {
    text.push(
      <div
        className="text-truncate"
        key={title}
        title={title}
      >{`Title: ${title} `}</div>
    );
  }

  // DIMENSIONS
  if (propsToShow[MapView.MetaData.DIMENSIONS]) {
    text.push(
      <div
        key={`${height}-${width}`}
      >{`Dimensions: ${height} ft x ${width} ft`}</div>
    );
  }

  // LTS
  if (propsToShow[MapView.MetaData.LTS]) {
    const ltsRating = ltsSummary.ltsRating || "";
    text.push(
      <div key={ltsRating}>{`Lts: ${
        ltsRating ? ltsRating.toFixed(2) + "/5" : "--"
      }`}</div>
    );
  }

  // IMPRESSIONS
  if (propsToShow[MapView.MetaData.OTS]) {
    // Checking Org is Subscribed or Not and is it seller
    const isSubscribed = getIsSubscriptionActive();
    const isSeller = checkIsSellerUser();

    const finalImpressionsToShow =
      !isSubscribed && isSeller
        ? maskCharacter(ots)
        : formatText(toLocaleString(ots));

    text.push(<div key={ots}>Impressions: {finalImpressionsToShow}</div>);
  }

  // Returning the Updated TEXT-OBJECT
  return text;
}

// IMAGE
function getPopupMediaImage(media = {}, propsToShow) {
  const imageUrl = media.fileItems[0]?.thumbnailUrl || "";

  if (!propsToShow[MapView.MetaData.IMAGE]) {
    return null;
  }

  if (!imageUrl) {
    return <DefaultImage />;
  }

  return <img className="media__site-image" src={imageUrl} alt="media-image" />;
}

function MediaSiteSelectionButton({ media }) {
  const { id: campaignId } = useParams();

  // dispatch
  const dispatch = useDispatch();

  const { mediaId } = media;

  // sites added to campaign
  const selectedMediaSites = useSelector(
    (state) => state.mapViewMediaSites.filteredMediaSites
  );

  // selectedMediaSite
  const selectedMediaSite =
    selectedMediaSites.find((mediaSite) => mediaSite.mediaId === mediaId) || {};

  // loader for selecting media site
  const ismediaSelectedLoader = useSelector(
    (state) => state.exploreSitesSelection.selectedSitesLoading[mediaId]
  );

  // loader for removing media site
  const isMediaRemovedLoader = useSelector(
    (state) =>
      state.mapViewMediaSites.removeMediaSitesLoading[selectedMediaSite.id]
  );

  // Add media to campaign
  function selectMedia() {
    dispatch(selectExploreSite(campaignId, media));
  }

  // remove media from campaign
  function unSelectMedia() {
    dispatch(removeMediaSite(campaignId, selectedMediaSite));
  }

  if (Object.keys(selectedMediaSite).length > 0) {
    return (
      <div className="p-2 text-right">
        <button
          type="button"
          className="btn btn-outline-success shadow-none mt-1 added-button"
          onClick={unSelectMedia}
        >
          {!isMediaRemovedLoader && <i className="fa fa-check "></i>}{" "}
          <b>Added</b>
          {isMediaRemovedLoader && (
            <Spinner className="ml-2 spinner-border-sm" />
          )}
        </button>
      </div>
    );
  }
  return (
    <div className="p-2 text-right">
      <button
        type="button"
        className="btn add-button shadow-none btn-outline-primary mt-1"
        onClick={selectMedia}
      >
        <b>Add</b>
        {ismediaSelectedLoader && (
          <Spinner className="ml-2 spinner-border-sm" />
        )}
      </button>
    </div>
  );
}

function MediaDetails({ media, propsToShow }) {
  const markerText = getMediaMarkerPopupText(media, propsToShow);
  const mediaImage = getPopupMediaImage(media, propsToShow);

  const isFromCampaignPlanner = useUrlKeyCheck(UrlKeys.campaignPlanning);

  return (
    <>
      <div className="d-flex">
        <div>{mediaImage}</div>
        <div className="col-9 font-weight-bold">{markerText}</div>
      </div>
      {isFromCampaignPlanner && (
        <div className="text-right mt-1">
          <MediaSiteSelectionButton media={media} />
        </div>
      )}
    </>
  );
}

/**
 * Map Media Marker Popup
 */
export default function MapMediaMarkerPopup({
  mediaList = [],
  icon,
  propsToShow = DEFAULT_PROPS_TO_SHOW,
}) {
  return (
    <>
      {mediaList.map((media, i) => (
        <Marker
          key={i}
          position={[media.latitude, media.longitude]}
          icon={icon}
        >
          <Popup>
            <MediaDetails media={media} propsToShow={propsToShow} />
          </Popup>
        </Marker>
      ))}
    </>
  );
}
