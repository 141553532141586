import "./Carousel.scss";

function Indicators({ size }) {
  const indicatorItems = [];
  for (let i = 0; i < size; i++) {
    indicatorItems.push(
      <li
        key={i}
        data-target="#carousel-container"
        data-slide-to={i}
        className={`${i === 0 ? "active" : ""}`}
      ></li>
    );
  }
  return <ol className="carousel-indicators text-primary">{indicatorItems}</ol>;
}

function Content({ urls }) {
  return (
    <div className="carousel-inner carousel-content-container">
      {urls.map((url, index) => (
        <div
          key={index}
          className={`carousel-item carousel-image-container ${
            index === 0 ? "active" : ""
          }`}
        >
          <img
            className="carousel-img d-block w-100"
            src={url}
            alt={`slide ${index + 1}`}
          />
        </div>
      ))}
    </div>
  );
}

function Controls() {
  return (
    <>
      <a
        className="carousel-control-prev"
        href="#carousel-container"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carousel-container"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </>
  );
}

function Carousel({ urls }) {
  if (!urls.length) {
    return null;
  }
  return (
    <div
      id="carousel-container"
      className="carousel slide image-carousel"
      data-ride="carousel"
    >
      <Indicators size={urls.length} />
      <Content urls={urls} />
      <Controls />
    </div>
  );
}

Carousel.defaultProps = {
  urls: [],
};

export default Carousel;
